import { Box } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import DialogCustom from 'commons/DialogCustom';
import GroupActionButton from 'commons/GroupActionButton';
import API from 'configs/API';
import { isEditionStatusAvailable } from 'configs/Constant';
import useDeleteArtwork from 'hooks/useDeleteArtwork';
import useRole from 'hooks/useRole';
import useUpdateArtwork from 'hooks/useUpdateArtwork';
import useUploadImageS3 from 'hooks/useUploadImageS3';
import { ToastTopHelper } from 'utils/utils';

import EditArtworkCertificate from '../EditArtworkCertificate';
import EditArtworkPrice from '../EditArtworkPrice';
import EditArtworkVisible from '../EditArtworkVisible';
import useUpdateEditions from './hooks/useUpdateEditions';
import useStyles from './styles';

const EditArtworkActionBlock = ({ artworkFormState }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const user = useSelector((state) => state.auth.account.user);

  const { isArtist, isCollector } = useRole();
  const queryClient = useQueryClient();

  const [isShowDialog, setIsShowDialog] = useState(false);
  const { mutate: deleteArtwork } = useDeleteArtwork();

  const { handleSubmit, watch, setValue, formState } = artworkFormState;
  const { defaultValues } = formState;
  const { isShowRejectTagRequest } = watch();

  const { uploadListImage } = useUploadImageS3();
  const { id: artworkId } = useParams();
  const { mutate: updateArtwork } = useUpdateArtwork(artworkId);
  const { mutateAsync: updateEditions } = useUpdateEditions();

  const navigate = useNavigate();

  const editions = useMemo(() => {
    return watch('editions');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(watch('editions'))]);

  const isStatusAvailable = useMemo(() => {
    return isEditionStatusAvailable(editions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(editions)]);

  const handleUpdateArtwork = async (data) => {
    const listImages = data?.uploadFiles || [];
    const uploadS3Response = await uploadListImage(listImages);

    const allSuccessful = uploadS3Response.every((response) => response?.success === true);

    if (!allSuccessful) {
      ToastTopHelper.error(t('upload_file_failed'));
      return;
    }

    const formData = {
      title: data?.title,
      images: data?.keyImages,
      year_created: data?.yearCreated,
      period_created: data?.periodYear,
      style: data?.style,
      subject: data?.subject,
      medium: data?.medium,
      orientation: data?.orientation?.id,
      size: {
        height: data?.height,
        width: data?.width,
        depth: data?.depth,
      },
      description: data?.description,
      note: data?.note,
      price: data?.price,
      is_hide_price: data?.isHidePriceVisible,
      is_ask_price_visible: data?.isAskPriceVisible,
      contact_information: data?.contactInformation,
      artist_name: data?.artistName || data?.selectedUser?.name || null,
      contact_info_artist: data?.contactInfoArtist,
      is_public: data?.isPublic,
    };

    if (isCollector) {
      const selectedUserId = data?.selectedUser?.id;
      formData.edition = data?.edition;

      if (selectedUserId && !isShowRejectTagRequest) {
        formData.tag_request = {
          request_by: user?.id,
          request_to: selectedUserId,
        };
      }
    }

    await updateEditions(data?.editions);

    updateArtwork(formData, {
      onSuccess: () => {
        ToastTopHelper.success(t('update_successfully'));
        navigate(-1);
      },
    });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleShowPopUpConfirmDelete = () => {
    setIsShowDialog(true);
  };

  const handleCancelDelete = () => {
    setIsShowDialog(false);
  };

  const handleDeleteArtwork = (e) => {
    e.stopPropagation();
    deleteArtwork(defaultValues.artworkId, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [API.ARTWORK.LIST_GALLERY_ARTWORK] });
        ToastTopHelper.success(
          t(
            defaultValues.hasCertificate
              ? 'text_move_artwork_to_store'
              : 'text_delete_artwork_success',
          ),
          navigate(-1),
        );
      },
    });

    setIsShowDialog(false);
  };

  useEffect(() => {
    if (isArtist && !isStatusAvailable) {
      setValue('price', '');
      setValue('isAskPriceVisible', false);
      setValue('isHidePriceVisible', false);
      setValue('contactInformation', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStatusAvailable, isArtist, JSON.stringify(formState?.defaultValues)]);

  useEffect(() => {
    if (isArtist && isStatusAvailable) {
      setValue('price', defaultValues?.price);
      setValue('isAskPriceVisible', defaultValues?.isAskPriceVisible);
      setValue('isHidePriceVisible', defaultValues?.isHidePriceVisible);
      setValue('contactInformation', defaultValues?.contactInformation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStatusAvailable, isArtist, JSON.stringify(formState?.defaultValues)]);

  return (
    <Box>
      {isArtist && isStatusAvailable && <EditArtworkPrice artworkFormState={artworkFormState} />}
      {isCollector && <EditArtworkPrice artworkFormState={artworkFormState} />}

      <EditArtworkCertificate artworkFormState={artworkFormState} />

      <EditArtworkVisible artworkFormState={artworkFormState} />

      <GroupActionButton
        mainButtonText={t('update')}
        subButtonLeftText={t('cancel')}
        subButtonRightText={t('delete_artwork')}
        onMainButtonClick={handleSubmit(handleUpdateArtwork)}
        onSubButtonRight={handleShowPopUpConfirmDelete}
        onSubButtonLeft={handleCancel}
        groupActionButtonWrapStyle={classes.groupActionWrapper}
        subButtonRightStyle={classes.subButtonRightStyle}
      />

      <DialogCustom
        isShowDialog={isShowDialog}
        dialogTitle={t('delete_artwork')}
        textCancel={t('cancel')}
        textSubmit={t('delete')}
        onCancel={handleCancelDelete}
        onSubmit={handleDeleteArtwork}
        submitButtonStyle={clsx(classes.buttonDialog, classes.buttonDanger)}
        cancelButtonStyle={clsx(classes.buttonDialog)}
      >
        <div className={classes.wrapDialogContent}>
          {t(
            defaultValues.hasCertificate
              ? 'text_confirm_delete_artwork_has_certificate'
              : 'text_confirm_delete_artwork_no_certificate',
          )}
        </div>
      </DialogCustom>
    </Box>
  );
};

export default EditArtworkActionBlock;
