import { Tab, Tabs } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import TabPanel from 'commons/TabPanel';
import API from 'configs/API';

import ArtworkCollection from '../ArtworkCollection';
import GalleryArtwork from '../GalleryArtwork';
import SpeedDialNavigation from '../SpeedDialNavigation';
import useStyles from './styles';

const GalleryTabContent = ({ visibleSetting }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const user = useSelector((state) => state.auth.account.user);
  const [searchParams, setSearchParams] = useSearchParams();
  const tabValue = Number(searchParams.get('tab') || 1);

  const { artistId } = useParams();
  const { data: artworksCount } = useQuery({
    queryKey: [API.ARTWORK.ARTWORK_COUNT, { owner_id: artistId }],
  });
  const { data: collectionsCount } = useQuery({
    queryKey: [API.ARTWORK.COLLECTION_COUNT, { owner_id: artistId }],
  });

  const isOwner = user?.id === parseInt(artistId);

  const handleTabChange = (e, newValue) => {
    searchParams.set('tab', newValue);
    searchParams.delete('search');
    searchParams.delete('page');
    setSearchParams(searchParams, { replace: true });
  };

  const getLabel = ({ text, number, isShowNumber }) => {
    if (!isShowNumber) {
      return text;
    }

    return `${text} ${number}`;
  };

  return (
    <>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        className={classes.tabs}
      >
        <Tab
          label={getLabel({
            text: t('all_works'),
            number: artworksCount || '',
            isShowNumber: visibleSetting?.is_public_artwork,
          })}
          value={1}
          className={classes.tab}
        />

        <Tab
          label={getLabel({
            text: t('collection'),
            number: collectionsCount || '',
            isShowNumber: visibleSetting?.is_public_artwork,
          })}
          value={2}
          className={classes.tab}
        />
      </Tabs>

      <TabPanel
        value={tabValue}
        index={1}
      >
        <GalleryArtwork />
      </TabPanel>

      <TabPanel
        value={tabValue}
        index={2}
      >
        <ArtworkCollection />
      </TabPanel>
      {isOwner && <SpeedDialNavigation />}
    </>
  );
};

export default GalleryTabContent;
