import { Table } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import usePagination from 'hooks/usePagination';
import useArtworksSoldDonated from 'pages/ManageArtwork/hooks/useArtworksSoldDonated';

import TableWrapper from '../../TableWrapper';
import TableArtworkCollectorBody from '../Common/TableArtworkCollectorBody';
import TableArtworkCollectorHead from '../Common/TableArtworkCollectorHead';

const TableShowSoldDonatedRoleCollector = () => {
  const user = useSelector((state) => state.auth.account.user);
  const { data: artworks } = useArtworksSoldDonated(user?.id);

  const count = artworks?.count || 0;
  const pageSize = artworks?.page_size || 10;

  const { page, totalPage, onPageChange } = usePagination(count, pageSize);

  const listArtwork = artworks?.results || [];

  return (
    <TableWrapper
      page={page}
      totalPage={totalPage}
      onPageChange={onPageChange}
    >
      <Table>
        <TableArtworkCollectorHead />
        <TableArtworkCollectorBody
          listArtwork={listArtwork}
          page={page}
          pageSize={artworks?.page_size}
        />
      </Table>
    </TableWrapper>
  );
};

export default TableShowSoldDonatedRoleCollector;
