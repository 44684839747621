import { Box, ImageList, useMediaQuery } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import PaginationCustom from 'commons/PaginationCustom';
import { BREAKPOINTS } from 'configs/Constant';
import usePagination from 'hooks/usePagination';
import useGalleryArtworks from 'pages/Gallery/hooks/useGalleryArtworks';
import useStatusCanRequestCertificate from 'react-query/hooks/queries/useStatusCanRequestCertificate';

import GalleryArtworkItem from '../GalleryArtworkItem';
import useStyles from './styles';

const GalleryArtwork = () => {
  const { artistId } = useParams();
  const classes = useStyles();

  const { data: artworks, isPending } = useGalleryArtworks(artistId);
  const { data: status } = useStatusCanRequestCertificate();
  const { page, totalPage, onPageChange } = usePagination(artworks?.count, artworks?.page_size);

  const user = useSelector((state) => state.auth.account.user);

  const isLaptop = useMediaQuery(BREAKPOINTS.laptop);
  const isMobile = useMediaQuery(BREAKPOINTS.mobile);
  const isOwner = user?.id === parseInt(artistId);

  if (isPending) {
    return;
  }

  const listArtwork = artworks?.results;

  const handlePageChange = (_, page) => {
    onPageChange(page);
  };

  const getColumns = () => {
    if (isLaptop) {
      return 4;
    }
    if (isMobile) {
      return 1;
    }
    return 2; //tablet
  };

  return (
    <Box sx={{ mt: 3 }}>
      <ImageList
        variant="masonry"
        cols={getColumns()}
        gap={40}
      >
        <>
          {listArtwork?.map((artwork) => (
            <GalleryArtworkItem
              artwork={artwork}
              status={status}
              key={artwork?.id}
              isOwner={isOwner}
            />
          ))}
        </>
      </ImageList>

      {!!totalPage && (
        <PaginationCustom
          paginationStyle={classes.pagination}
          count={totalPage}
          page={page}
          onChange={handlePageChange}
        />
      )}
    </Box>
  );
};

export default GalleryArtwork;
