import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { Box, Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ArtworkLikeStatProvider from 'commons/ArtworkLikeStatProvider';
import Breadcrumbs from 'commons/Breadcrumbs';
import ShareSocialPopup from 'commons/ShareSocialPopup';
import Text from 'commons/Text';
import Colors from 'configs/Colors';
import {
  CERTIFICATE,
  SHOW,
  STATUS_REQUEST_KEY,
  USER_ROLE_BY_KEY,
  getLabelForOption,
} from 'configs/Constant';
import Routes from 'utils/Route';
import { isValidEmailAddress, isValidURL } from 'utils/Validation';
import { formatCurrency, getTextEdition } from 'utils/utils';

import AttributeField from '../ArtworkInfo/AttributeField';
import ArtworkLikeUI from '../ArtworkLikeUI';
import CertificateDialog from '../CertificateDialog';
import useStyles from './styles';

const ArtworkDetailLeft = ({ breadCrumbs, artwork }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpenSharePopup, setIsOpenSharePopup] = useState(false);

  const language = useSelector((state) => state?.language?.currentLanguage);

  const {
    title,
    artist_name,
    status,
    year_created,
    period_created,
    size,
    certificate,
    medium,
    total_edition,
    price,
    editions,
    currency,
    is_public_certificate,
    contact_information,
    artist_tag_request,
    owner,
    artist,
  } = artwork || {};

  const { request_to, status: statusTagRequest } = artist_tag_request || {};
  const priceFormat = formatCurrency(price);

  const sizeData =
    size?.height &&
    size?.width &&
    `${size?.height} x ${size?.width} ${size?.depth ? ` x ${size?.depth}` : ''}cm `;

  const isHidePrice = artwork?.is_hide_price;
  const isShowAskPrice = artwork?.is_ask_price_visible;
  const isOpen = searchParams.get(CERTIFICATE) === SHOW;

  const isStatusTagRequestApproved = statusTagRequest === STATUS_REQUEST_KEY['REQUEST_APPROVED'];

  const handleContactClick = () => {
    if (contact_information) {
      if (isValidURL(contact_information)) {
        window.open(contact_information, '_blank');
      } else if (isValidEmailAddress(contact_information)) {
        window.location.href = `mailto:${contact_information}`;
      }
    }
  };

  const handleShowDialog = () => {
    if (is_public_certificate) {
      searchParams.set(CERTIFICATE, SHOW);
      setSearchParams(searchParams, { replace: true });
    }
  };

  const handleCloseDialog = () => {
    searchParams.delete(CERTIFICATE);
    setSearchParams(searchParams, { replace: true });
  };

  const edition = editions?.find(
    (edition) => edition?.edition_number === certificate?.edition_number,
  );

  const getTextArtistName = () => {
    const isHasArtistTagRequest = request_to && Object.keys(request_to || {})?.length > 0;

    if (owner?.role === USER_ROLE_BY_KEY.ARTIST) {
      return artist?.name || artist_name;
    }

    if (owner?.role === USER_ROLE_BY_KEY.COLLECTOR && !artist_name && !isHasArtistTagRequest) {
      return 'N/A';
    }

    if (
      owner?.role === USER_ROLE_BY_KEY.COLLECTOR &&
      isHasArtistTagRequest &&
      isStatusTagRequestApproved
    ) {
      return request_to?.name;
    }

    return artist_name;
  };

  const calculateTotalStatus = (editions, targetStatus) => {
    if (!Array.isArray(editions)) {
      return 0;
    }
    let totalCount = 0;
    for (let edition of editions) {
      if (edition.status === targetStatus) {
        totalCount++;
      }
    }
    return totalCount;
  };

  const totalStatusMapWithEditionStatus = calculateTotalStatus(
    editions,
    certificate?.edition_status,
  );

  const getOnClickHandler = () => {
    if (owner?.role === USER_ROLE_BY_KEY.COLLECTOR && isStatusTagRequestApproved) {
      navigate(Routes.GalleryDetail(request_to?.id));
      return;
    }

    if (owner?.role === USER_ROLE_BY_KEY.ARTIST) {
      navigate(Routes.GalleryDetail(owner?.id));
    }
  };

  const handleOpenSocialPopup = () => {
    setIsOpenSharePopup(true);
  };

  const handleCloseSocialPopup = () => {
    setIsOpenSharePopup(false);
  };

  return (
    <Box className={classes.wrapHeader}>
      <Breadcrumbs
        items={breadCrumbs}
        breadcrumbsStyle={classes.breadcrumbsStyle}
      />
      <Text
        fontWeightBold
        color={Colors.Grey10}
        type="2xl"
        variant="h1"
        className={classes.textTitle}
      >
        {title}
        {title && (year_created || period_created) && <span className={classes.spacing}>, </span>}
        {year_created || period_created}
      </Text>

      <Box className={classes.wrapAttributeField}>
        <AttributeField
          attributeName={`${t('artist')}:`}
          attributeValue={getTextArtistName()}
          attributeValueStyle={clsx(classes.link, {
            [classes.linkCustom]:
              owner?.role === USER_ROLE_BY_KEY.COLLECTOR && !isStatusTagRequestApproved,
          })}
          onValueClick={getOnClickHandler}
        />

        <AttributeField
          attributeName={`${t('year_of_creation')}:`}
          attributeValue={year_created || period_created}
        />

        <AttributeField
          attributeName={`${t('medium_material')}:`}
          attributeValue={getLabelForOption(medium, language)}
        />

        <AttributeField
          attributeName={`${t('measurement_w_h')}:`}
          attributeValue={sizeData}
        />

        {certificate?.edition_number && (
          <AttributeField
            attributeName={`${t('edition')} ${t(edition?.status)}:`}
            attributeValue={getTextEdition(t, totalStatusMapWithEditionStatus, total_edition)}
          />
        )}

        <AttributeField
          attributeName={`${t('certificate_of_authenticity')}:`}
          attributeValue={artwork?.certificate && t('issued_on_gladius_art')}
          attributeValueStyle={is_public_certificate && classes.link}
          onValueClick={handleShowDialog}
        />

        <AttributeField
          attributeName={`${t('status')}:`}
          attributeValue={t(status?.key)}
          attributeValueStyle={classes.attributeField}
        />
      </Box>

      <Box className={classes.wrapBoxPriceAndIcon}>
        <Divider />
        {priceFormat && priceFormat !== 0 && !isHidePrice && (
          <div className={classes.wrapPrice}>
            <Text>{t('price')}:</Text>
            <Text
              className={classes.price}
              fontWeightBold
            >
              {`${priceFormat} ${currency}`}
            </Text>
          </div>
        )}
        {isShowAskPrice && (
          <div className={classes.wrapPrice}>
            <Text>{`${t('price')}:`}</Text>
            <Text
              className={classes.askPrice}
              onClick={handleContactClick}
            >
              {`${t('ask_for_price')}`}
            </Text>
          </div>
        )}
        <div className={classes.wrapIcon}>
          <ArtworkLikeStatProvider artwork={artwork}>
            <ArtworkLikeUI artworkId={artwork?.id} />
          </ArtworkLikeStatProvider>

          <Button
            variant="outlined"
            fullWidth
            className={classes.button1}
            onClick={handleOpenSocialPopup}
          >
            <ShareOutlinedIcon />
          </Button>
          {isShowAskPrice && (
            <Button
              variant="contained"
              fullWidth
              className={classes.button2}
              onClick={handleContactClick}
            >
              <EmailOutlinedIcon />
              <Text className={classes.textInquire}>{t('inquire')}</Text>
            </Button>
          )}
        </div>
      </Box>

      {artwork?.certificate && (
        <CertificateDialog
          open={is_public_certificate ? isOpen : false}
          onClose={handleCloseDialog}
          artwork={artwork}
          certificate={artwork?.certificate}
        />
      )}

      <ShareSocialPopup
        open={isOpenSharePopup}
        onClose={handleCloseSocialPopup}
        fullWidth
        maxWidth="sm"
      />
    </Box>
  );
};

export default ArtworkDetailLeft;
