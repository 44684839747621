import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { STATUS_REQUEST_KEY } from 'configs/Constant';
import GridLayout from 'layouts/GridLayout';
import { editArtworkSchema } from 'pages/EditArtwork/validates/editArtworkSchema';

import EditArtworkActionBlock from '../EditArtworkActionBlock';
import EditArtworkDetailBlock from '../EditArtworkDetailBlock';

const EditArtworkMainContent = ({ artwork }) => {
  const role = useSelector((state) => state.auth.account.user.role);

  const artworkFormState = useForm({
    defaultValues: {
      editions: artwork?.editions,
      title: artwork?.title,
      selectedUser: artwork?.artist_tag_request?.request_to,
      artistName: artwork?.artist_name || artwork?.artist?.name,
      yearCreated: artwork?.year_created,
      periodYear: artwork?.period_created,
      checkedPeriod:
        artwork?.year_created || artwork?.period_created ? !artwork?.year_created : false,
      editionVolume: artwork?.total_edition,
      style: artwork?.style,
      subject: artwork?.subject,
      medium: artwork?.medium,
      orientation: artwork?.orientation,
      height: artwork?.size?.height,
      width: artwork?.size?.width,
      depth: artwork?.size?.depth,
      description: artwork?.description,
      note: artwork?.note,
      images: artwork?.images,
      keyImages: artwork?.key_images,
      currency: artwork?.currency,
      isPublicCertificate: artwork?.is_public_certificate,
      isPublic: artwork?.is_public,
      price: artwork?.price ? parseFloat(artwork.price) : null,
      isHidePriceVisible: artwork?.is_hide_price,
      isAskPriceVisible: artwork?.is_ask_price_visible,
      contactInformation: artwork?.contact_information,
      hasCertificate: artwork?.has_certificate,
      checkedArtistUnknown: !artwork?.artist_tag_request?.request_to?.name && !artwork?.artist_name,
      role,
      edition: artwork?.editions[0],
      artworkId: artwork?.id,
      inputSearchUser: '',
      contactInfoArtist: '',
      isShowCheckBoxCustom: !artwork?.artist_tag_request?.request_to?.name && !artwork?.artist_name,
      isShowInputArtistName: !!artwork?.artist_name,
      artistTagRequest: artwork?.artist_tag_request,
      isShowDialogArtistName: false,
      isShowDialogCancelTagRequest: false,
      isShowRejectTagRequest:
        artwork?.artist_tag_request?.request_to?.id ===
          artwork?.artist_tag_request?.request_to?.id &&
        artwork?.artist_tag_request?.status === STATUS_REQUEST_KEY.REQUEST_DENIED,
    },
    mode: 'onChange',
    resolver: yupResolver(editArtworkSchema),
  });

  return (
    <Box sx={{ pt: 3 }}>
      <GridLayout>
        <EditArtworkDetailBlock
          artworkFormState={artworkFormState}
          artwork={artwork}
        />
        <EditArtworkActionBlock artworkFormState={artworkFormState} />
      </GridLayout>
    </Box>
  );
};

export default EditArtworkMainContent;
